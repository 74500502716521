/* Jobs Widget */

#ja-jobs-widget {
	position: relative;
}

.ja-loading {
	position: absolute;
	top: 10px;
	right: 10px;
}

.ja-loading.left {
	left: 10px;
	right: auto;
}


/* Forms */
.ja-form {
	margin-bottom: 3em;
}

.ja-form .ja-field-container {
	margin-bottom: 0.5em;
}

.ja-form .ja-field-container:before,
.ja-form .ja-field-container:after {
	content: "";
	display: table;
}

.ja-form .ja-field-container:after {
	clear: both;
}

.ja-form .ja-field-container {
	zoom: 1;
}

.ja-form .ja-field-container label {
	display: block;
	float: left;
	width: 100px;
	padding-top: 6px;
}

.ja-form .ja-field-container .ja-field {
	float: left;
}

.ja-form .ja-field-container select,
.ja-form .ja-field-container textarea,
.ja-form .ja-field-container input[type="text"],
.ja-form .ja-field-container input[type="password"],
.ja-form .ja-field-container input[type="datetime"],
.ja-form .ja-field-container input[type="datetime-local"],
.ja-form .ja-field-container input[type="date"],
.ja-form .ja-field-container input[type="month"],
.ja-form .ja-field-container input[type="time"],
.ja-form .ja-field-container input[type="week"],
.ja-form .ja-field-container input[type="number"],
.ja-form .ja-field-container input[type="email"],
.ja-form .ja-field-container input[type="url"],
.ja-form .ja-field-container input[type="search"],
.ja-form .ja-field-container input[type="tel"],
.ja-form .ja-field-container input[type="color"] {
	font-family: Helvetica, Arial, sans-serif;
	font-size: 1em;
	color: #333;
	border: solid 1px #ccc;
}

.ja-form .ja-field-container select {
	width: 240px;
	padding: 2px;
}

.ja-form .ja-field-container textarea {
	width: 230px;
	padding: 3px 4px;
}

.ja-form .ja-field-container input[type="text"],
.ja-form .ja-field-container input[type="password"],
.ja-form .ja-field-container input[type="datetime"],
.ja-form .ja-field-container input[type="datetime-local"],
.ja-form .ja-field-container input[type="date"],
.ja-form .ja-field-container input[type="month"],
.ja-form .ja-field-container input[type="time"],
.ja-form .ja-field-container input[type="week"],
.ja-form .ja-field-container input[type="number"],
.ja-form .ja-field-container input[type="email"],
.ja-form .ja-field-container input[type="url"],
.ja-form .ja-field-container input[type="search"],
.ja-form .ja-field-container input[type="tel"],
.ja-form .ja-field-container input[type="color"] {
	width: 230px;
	padding: 3px 4px;
}

.ja-form .ja-field-container .checkbox:after {
	content: ".";
	display: block;
	height: 0;
	clear: both;
	visibility: hidden;
}

.ja-form .ja-field-container .checkbox {
	display: block;
	margin-left: 95px;
}

.ja-form .ja-field-container .checkbox br {
	clear: left;
}

.ja-form .ja-field-container .checkbox input {
	width: 15px;
	height: 19px;
	float: left;
}

.ja-form .ja-field-container .checkbox label {
	height: 21px;
	width: auto;
	line-height: 21px;
	float: left;
	padding: 2px 12px 0 5px;
}


.ja-form .ja-submit {
	margin-top: 1em;
	margin-left: 100px;
	vertical-align: middle;
}

.ja-form .ja-submit .ja-loading {
	position: relative;
	top: 0;
	left: 0;
	margin-left: 10px;
	vertical-align: middle;
}

.ja-button {
	font-size: 1em;
	font-family: Helvetica, Arial, sans-serif;
	font-weight: normal;
	-moz-border-radius: 5px;
	-webkit-border-radius: 5px;
	border-radius: 5px;
	border: 1px solid #dcdcdc;
	padding: 4px 18px;
	text-decoration: none;
	background: -webkit-gradient( linear, left top, left bottom, color-stop(5%, #f9f9f9), color-stop(100%, #e9e9e9) );
	background: -moz-linear-gradient( center top, #f9f9f9 5%, #e9e9e9 100% );
	background: -ms-linear-gradient( top, #f9f9f9 5%, #e9e9e9 100% );
	filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#f9f9f9', endColorstr='#e9e9e9');
	background-color: #f9f9f9;
	color: #333333;
	display: inline-block;
	text-shadow: 1px 1px 1px #ffffff;
	-webkit-box-shadow: inset 1px 1px 0px 0px #ffffff;
	-moz-box-shadow: inset 1px 1px 0px 0px #ffffff;
	box-shadow: inset 1px 1px 0px 0px #ffffff;
}

.ja-button:hover {
	background: -webkit-gradient( linear, left top, left bottom, color-stop(5%, #e9e9e9), color-stop(100%, #f9f9f9) );
	background: -moz-linear-gradient( center top, #e9e9e9 5%, #f9f9f9 100% );
	background: -ms-linear-gradient( top, #e9e9e9 5%, #f9f9f9 100% );
	filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#e9e9e9', endColorstr='#f9f9f9');
	background-color: #e9e9e9;
	cursor: pointer !important;
}

.ja-button:active {
	position: relative;
	top: 1px;
}

.ja-form .ja-field-container .error {
	color: #990000;
	border-color: #990000;
}

.ja-form .ja-field-container .validation-error {
	display: block;
	clear: both;
	margin-top: 5px;
	color: #aa0000;
}

.ja-form .ja-error-container {
	color: #aa0000;
	margin-top: 2em;
	margin-left: 100px;
}

.ja-form .ja-error-container p {
	margin-bottom: 0;
}

/* End forms */


/* Job list */

.ja-job-list-container {
}

.ja-job-list-container .no-jobs-content {
}

.ja-job-list {
	margin-bottom: 3em;
}

.ja-job-list .job {
	margin-bottom: 1em;
	padding: 0.5em;
}

.ja-job-list .job.alt {
	background-color: #f5f5f5;
}

.ja-job-list .job.hot {
	background-color: #fffdfd;
}

.ja-job-list .job .title {
    font-size:28px;
}

.ja-job-list .job .title a {
}

.ja-job-list .job .meta {
	margin-bottom: 1em;
}

.ja-job-list .job .meta:before,
.ja-job-list .job .meta:after {
	content: "";
	display: table;
}

.ja-job-list .job .meta:after {
	clear: both;
}

.ja-job-list .job .meta {
	zoom: 1;
}

.ja-job-list .job .meta p {
	font-size: 0.9em;
	margin-bottom: 0.5em;
}

.ja-job-list .job .meta .classifications {
	float: left;
	list-style: none;
	width: 50%;
	margin: 0 0 0.5em 0;
}

.ja-job-list .job .meta .classifications li {
	display: inline;
	margin-right: 0.5em;
}

.ja-job-list .job .meta .date-posted {
	float: right;
	width: 50%;
	text-align: right;
}

.ja-job-list .job .meta .salary {
	clear: left;
	float: left;
}

.ja-job-list .job .meta .reference {
	float: right;
	text-align: right;
}

.ja-job-list .job .summary {
}

.ja-job-list .job .view-details {
	font-size: 0.9em;
	font-family: Helvetica, Arial, sans-serif;
	font-weight: normal;
	-moz-border-radius: 3px;
	-webkit-border-radius: 3px;
	border-radius: 3px;
	border: 1px solid #dcdcdc;
	padding: 4px 10px;
	text-decoration: none;
	background: -webkit-gradient( linear, left top, left bottom, color-stop(5%, #f9f9f9), color-stop(100%, #e9e9e9) );
	background: -moz-linear-gradient( center top, #f9f9f9 5%, #e9e9e9 100% );
	background: -ms-linear-gradient( top, #f9f9f9 5%, #e9e9e9 100% );
	filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#f9f9f9', endColorstr='#e9e9e9');
	background-color: #f9f9f9;
	color: #333333;
	display: inline-block;
	text-shadow: 1px 1px 1px #ffffff;
	-webkit-box-shadow: inset 1px 1px 0px 0px #ffffff;
	-moz-box-shadow: inset 1px 1px 0px 0px #ffffff;
	box-shadow: inset 1px 1px 0px 0px #ffffff;
}

.ja-job-list .job .view-details:hover {
	background: -webkit-gradient( linear, left top, left bottom, color-stop(5%, #e9e9e9), color-stop(100%, #f9f9f9) );
	background: -moz-linear-gradient( center top, #e9e9e9 5%, #f9f9f9 100% );
	background: -ms-linear-gradient( top, #e9e9e9 5%, #f9f9f9 100% );
	filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#e9e9e9', endColorstr='#f9f9f9');
	background-color: #e9e9e9;
	cursor: pointer !important;
}

.ja-job-list .job .view-details:active {
	position: relative;
	top: 1px;
}


/* End job list */


/* Pager */

.ja-pager-container {
}

.ja-pager-container:before,
.ja-pager-container:after {
	content: "";
	display: table;
}

.ja-pager-container:after {
	clear: both;
}

.ja-pager-container {
	zoom: 1;
}

.ja-pager {
	float: left;
	font-size: 0.9em;
}

.ja-pager:before,
.ja-pager:after {
	content: "";
	display: table;
}

.ja-pager:after {
	clear: both;
}

.ja-pager {
	zoom: 1;
}

.ja-pager span,
.ja-pager a {
	display: block;
	float: left;
	line-height: 23px;
	padding: 0 8px;
	margin-right: 3px;
	color: #333;
	background-color: #f3f3f3;
	border: solid 1px #ddd;
}

.ja-pager a:hover,
.ja-pager .current-page {
	color: #fff;
	background-color: #bbb;
	border-color: #999;
	text-decoration: none;
}

.ja-pager .previous,
.ja-pager .next {
}

.ja-pager .first-page,
.ja-pager .last-page {
}

.ja-pager .previous-ellipsis,
.ja-pager .next-ellipsis {
}


.ja-pager-summary {
	float: right;
	line-height: 25px;
	font-size: 0.9em;
}

/* End pager */


/* Job details */

.ja-job-details {
}

.ja-job-details .title {
}

.ja-job-details .meta {
	margin-bottom: 1em;
}

.ja-job-details .meta:before,
.ja-job-details .meta:after {
	content: "";
	display: table;
}

.ja-job-details .meta:after {
	clear: both;
}

.ja-job-details .meta {
	zoom: 1;
}

.ja-job-details .meta p {
	font-size: 0.9em;
	margin-bottom: 0.5em;
}

.ja-job-details .meta .classifications {
	float: left;
	width: 50%;
	list-style: none;
	margin: 0 0 0.5em 0;
}

.ja-job-details .meta .classifications li {
	display: inline;
	margin-right: 0.5em;
}

.ja-job-details .meta .date-posted {
	clear: right;
	float: right;
	width: 50%;
	text-align: right;
}

.ja-job-details .meta .salary {
	clear: left;
	float: left;
}

.ja-job-details .meta .reference {
	float: right;
	text-align: right;
}

.ja-job-details .bullet-points {
}

.ja-job-details .bullet-points li {
}

.ja-job-details .description {
}

.ja-job-details .apply {
	margin: 2em 0 0.5em 0;
	text-align: center;
}

.ja-job-details .apply .back-link {
	font-size: 0.9em;
	margin-right: 1em;
}

.ja-job-details .apply .ja-button {
}

/* End job details */

/* Application form iframe (external app forms) */

.ja-apply-iframe iframe {
	width: 100%;
	height: 500px;
	border: none;
}

.ja-apply-iframe .apply {
	margin: 2em 0 0.5em 0;
	text-align: center;
}

.ja-apply-iframe .apply .back-link {
	font-size: 0.9em;
}

/* End application form iframe */

/* Application form */

.ja-job-apply .ja-success-text {

}

.ja-job-apply .ja-submit .back-link {
	font-size: 0.9em;
	margin-left: 1em;
}

/* End application form */

/* Powered by JobAdder */

.powered-by-jobadder {
	color: #bbb;
	font-size: 0.9em;
	text-align: center;
	margin: 2em 0 1em 0;
}

.powered-by-jobadder a {
	color: #bbb;
}

.powered-by-jobadder a:hover {
	color: #aaa;
}

/* End powered by JobAdder */