// shared variables.
/* fonts */
$font: 'Public Sans', sans-serif;

/* colours */
$red: #db1c24;
$green: #006424;
$green-light: #A4B65D;
$light-blue: #0098dd;
$blue: #004e9a;
$navy: #1e1c52;
$navy-dark: #0a1732;

/* backgrounds */
$tint: #f0f0f0;
$light-grey: #d2d3d2;

/* button hover adjustments */
@mixin hover_darken($col: $blue){
    background-color: darken(desaturate(adjust-hue($col, 27), 37.29), 7.06);
}